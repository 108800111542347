import { default as indexTbXDWK8eQ9Meta } from "/vercel/path0/pages/cases-de-sucesso/index.vue?macro=true";
import { default as conta_45bancarias2Cj20KcQOMeta } from "/vercel/path0/pages/completar-cadastro/conta-bancaria.vue?macro=true";
import { default as dados_45documento9N6LAZPHQ3Meta } from "/vercel/path0/pages/completar-cadastro/dados-documento.vue?macro=true";
import { default as dados_45patrimoniaisyKDJDiWeoxMeta } from "/vercel/path0/pages/completar-cadastro/dados-patrimoniais.vue?macro=true";
import { default as declaracoesSfUt0zR7RjMeta } from "/vercel/path0/pages/completar-cadastro/declaracoes.vue?macro=true";
import { default as documentos2Wb3bjJmE5Meta } from "/vercel/path0/pages/completar-cadastro/documentos.vue?macro=true";
import { default as enderecoRH3OPmLX5bMeta } from "/vercel/path0/pages/completar-cadastro/endereco.vue?macro=true";
import { default as index86DyjHJUcRMeta } from "/vercel/path0/pages/completar-cadastro/index.vue?macro=true";
import { default as informacoes_45pessoaisFrjlULOZmjMeta } from "/vercel/path0/pages/completar-cadastro/informacoes-pessoais.vue?macro=true";
import { default as _91partner_93iMbdBCyiWqMeta } from "/vercel/path0/pages/crie-sua-conta/[partner].vue?macro=true";
import { default as _91slug_93RHrOUbVTVHMeta } from "/vercel/path0/pages/crie-sua-conta/b2b/[slug].vue?macro=true";
import { default as _91slug_9320GULkO4OkMeta } from "/vercel/path0/pages/crie-sua-conta/convite/[slug].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as confirmacao70Yjj3YzNBMeta } from "/vercel/path0/pages/investir/[operationId]/confirmacao.vue?macro=true";
import { default as detalhesStrVcJdZlsMeta } from "/vercel/path0/pages/investir/[operationId]/detalhes.vue?macro=true";
import { default as enquadramentoQJEQ0X0Sy4Meta } from "/vercel/path0/pages/investir/[operationId]/enquadramento.vue?macro=true";
import { default as index9byNyU8QEBMeta } from "/vercel/path0/pages/investir/[operationId]/index.vue?macro=true";
import { default as confirmar_45contah1Ik4YH8FOMeta } from "/vercel/path0/pages/investir/[operationId]/inicio/confirmar-conta.vue?macro=true";
import { default as criar_45contaSkDQ0tQphHMeta } from "/vercel/path0/pages/investir/[operationId]/inicio/criar-conta.vue?macro=true";
import { default as index4OzrzJy2oHMeta } from "/vercel/path0/pages/investir/[operationId]/inicio/index.vue?macro=true";
import { default as inicionUWCXNIOl5Meta } from "/vercel/path0/pages/investir/[operationId]/inicio.vue?macro=true";
import { default as _91indicationCode_93aysZWVdVIcMeta } from "/vercel/path0/pages/novo-investidor/[indicationCode].vue?macro=true";
import { default as _91operationId_93EBtug1RzAtMeta } from "/vercel/path0/pages/oportunidades/[operationId].vue?macro=true";
import { default as finalizadasrSqUoNhD9JMeta } from "/vercel/path0/pages/oportunidades/finalizadas.vue?macro=true";
import { default as historicoFExxblQTIIMeta } from "/vercel/path0/pages/oportunidades/historico.vue?macro=true";
import { default as index6E1pbgfjLrMeta } from "/vercel/path0/pages/oportunidades/index.vue?macro=true";
import { default as _91operationId_93Z4obi6RG6VMeta } from "/vercel/path0/pages/oportunidades/parceiro/[operationId].vue?macro=true";
import { default as indexB112Rdsc1eMeta } from "/vercel/path0/pages/originadoras/index.vue?macro=true";
import { default as _91slug_93tOIOCumazRMeta } from "/vercel/path0/pages/painel/ajuda/[slug].vue?macro=true";
import { default as indexlIaHMICzvpMeta } from "/vercel/path0/pages/painel/ajuda/index.vue?macro=true";
import { default as _91operationId_93ohfKyvzqhfMeta } from "/vercel/path0/pages/painel/detalhes-investimento/[operationId].vue?macro=true";
import { default as index4zvfyrDpQuMeta } from "/vercel/path0/pages/painel/index.vue?macro=true";
import { default as indexXyMqzEaGQPMeta } from "/vercel/path0/pages/painel/indicacao/index.vue?macro=true";
import { default as indexjriJGez7d4Meta } from "/vercel/path0/pages/painel/ir/index.vue?macro=true";
import { default as indexoRFjcRdhR3Meta } from "/vercel/path0/pages/painel/pendentes/index.vue?macro=true";
import { default as index4ge3cW3RvRMeta } from "/vercel/path0/pages/painel/perfil/alterar-email/index.vue?macro=true";
import { default as indextJn3HW0S6ZMeta } from "/vercel/path0/pages/painel/perfil/alterar-senha/index.vue?macro=true";
import { default as index51TLsa3RqnMeta } from "/vercel/path0/pages/painel/perfil/alterar-telefone/index.vue?macro=true";
import { default as adicionar5DbZF45VAkMeta } from "/vercel/path0/pages/painel/perfil/chave-pix/adicionar.vue?macro=true";
import { default as indexcNoZLf9kikMeta } from "/vercel/path0/pages/painel/perfil/chave-pix/index.vue?macro=true";
import { default as editarBlm77FzKTnMeta } from "/vercel/path0/pages/painel/perfil/dados-bancarios/[id]/editar.vue?macro=true";
import { default as adicionarv4gx5QDWNTMeta } from "/vercel/path0/pages/painel/perfil/dados-bancarios/adicionar.vue?macro=true";
import { default as indexSS8G5cbybdMeta } from "/vercel/path0/pages/painel/perfil/dados-bancarios/index.vue?macro=true";
import { default as index6PTCbwI1a2Meta } from "/vercel/path0/pages/painel/perfil/dados-patrimoniais/index.vue?macro=true";
import { default as index0iybtMw4bpMeta } from "/vercel/path0/pages/painel/perfil/dados-pessoais/index.vue?macro=true";
import { default as indexk4WHrAo1f1Meta } from "/vercel/path0/pages/painel/perfil/desativar-conta/index.vue?macro=true";
import { default as index9DLGyTJwaDMeta } from "/vercel/path0/pages/painel/perfil/documentos/index.vue?macro=true";
import { default as indexOCaA4S2QoKMeta } from "/vercel/path0/pages/painel/perfil/endereco/index.vue?macro=true";
import { default as indexnjmxEyuke3Meta } from "/vercel/path0/pages/painel/perfil/index.vue?macro=true";
import { default as _91assetSymbol_93GUBCS5HFzHMeta } from "/vercel/path0/pages/painel/wallet/[assetSymbol].vue?macro=true";
import { default as depositoVEnQ0vDnLTMeta } from "/vercel/path0/pages/painel/wallet/deposito.vue?macro=true";
import { default as indexkGK62At1OMMeta } from "/vercel/path0/pages/painel/wallet/index.vue?macro=true";
import { default as resgateuqCnhvWZRDMeta } from "/vercel/path0/pages/painel/wallet/resgate.vue?macro=true";
import { default as indexQpXVhpZ6xmMeta } from "/vercel/path0/pages/parceiros/index.vue?macro=true";
import { default as indexW75ZCJS0M5Meta } from "/vercel/path0/pages/politica-de-privacidade/index.vue?macro=true";
import { default as indexbe0Pcqyc0GMeta } from "/vercel/path0/pages/quem-somos/index.vue?macro=true";
import { default as index2zCys330d0Meta } from "/vercel/path0/pages/remocao-de-conta/index.vue?macro=true";
import { default as _91signupCode_93WVrBrc7ffnMeta } from "/vercel/path0/pages/seja-investidor/[signupCode].vue?macro=true";
import { default as indexzay5PkV141Meta } from "/vercel/path0/pages/termos-de-uso/index.vue?macro=true";
import { default as indexP3dhkR4w1VMeta } from "/vercel/path0/pages/tokenizacao/index.vue?macro=true";
export default [
  {
    name: indexTbXDWK8eQ9Meta?.name ?? "cases-de-sucesso",
    path: indexTbXDWK8eQ9Meta?.path ?? "/cases-de-sucesso",
    meta: indexTbXDWK8eQ9Meta || {},
    alias: indexTbXDWK8eQ9Meta?.alias || [],
    redirect: indexTbXDWK8eQ9Meta?.redirect,
    component: () => import("/vercel/path0/pages/cases-de-sucesso/index.vue").then(m => m.default || m)
  },
  {
    name: conta_45bancarias2Cj20KcQOMeta?.name ?? "completar-cadastro-conta-bancaria",
    path: conta_45bancarias2Cj20KcQOMeta?.path ?? "/completar-cadastro/conta-bancaria",
    meta: conta_45bancarias2Cj20KcQOMeta || {},
    alias: conta_45bancarias2Cj20KcQOMeta?.alias || [],
    redirect: conta_45bancarias2Cj20KcQOMeta?.redirect,
    component: () => import("/vercel/path0/pages/completar-cadastro/conta-bancaria.vue").then(m => m.default || m)
  },
  {
    name: dados_45documento9N6LAZPHQ3Meta?.name ?? "completar-cadastro-dados-documento",
    path: dados_45documento9N6LAZPHQ3Meta?.path ?? "/completar-cadastro/dados-documento",
    meta: dados_45documento9N6LAZPHQ3Meta || {},
    alias: dados_45documento9N6LAZPHQ3Meta?.alias || [],
    redirect: dados_45documento9N6LAZPHQ3Meta?.redirect,
    component: () => import("/vercel/path0/pages/completar-cadastro/dados-documento.vue").then(m => m.default || m)
  },
  {
    name: dados_45patrimoniaisyKDJDiWeoxMeta?.name ?? "completar-cadastro-dados-patrimoniais",
    path: dados_45patrimoniaisyKDJDiWeoxMeta?.path ?? "/completar-cadastro/dados-patrimoniais",
    meta: dados_45patrimoniaisyKDJDiWeoxMeta || {},
    alias: dados_45patrimoniaisyKDJDiWeoxMeta?.alias || [],
    redirect: dados_45patrimoniaisyKDJDiWeoxMeta?.redirect,
    component: () => import("/vercel/path0/pages/completar-cadastro/dados-patrimoniais.vue").then(m => m.default || m)
  },
  {
    name: declaracoesSfUt0zR7RjMeta?.name ?? "completar-cadastro-declaracoes",
    path: declaracoesSfUt0zR7RjMeta?.path ?? "/completar-cadastro/declaracoes",
    meta: declaracoesSfUt0zR7RjMeta || {},
    alias: declaracoesSfUt0zR7RjMeta?.alias || [],
    redirect: declaracoesSfUt0zR7RjMeta?.redirect,
    component: () => import("/vercel/path0/pages/completar-cadastro/declaracoes.vue").then(m => m.default || m)
  },
  {
    name: documentos2Wb3bjJmE5Meta?.name ?? "completar-cadastro-documentos",
    path: documentos2Wb3bjJmE5Meta?.path ?? "/completar-cadastro/documentos",
    meta: documentos2Wb3bjJmE5Meta || {},
    alias: documentos2Wb3bjJmE5Meta?.alias || [],
    redirect: documentos2Wb3bjJmE5Meta?.redirect,
    component: () => import("/vercel/path0/pages/completar-cadastro/documentos.vue").then(m => m.default || m)
  },
  {
    name: enderecoRH3OPmLX5bMeta?.name ?? "completar-cadastro-endereco",
    path: enderecoRH3OPmLX5bMeta?.path ?? "/completar-cadastro/endereco",
    meta: enderecoRH3OPmLX5bMeta || {},
    alias: enderecoRH3OPmLX5bMeta?.alias || [],
    redirect: enderecoRH3OPmLX5bMeta?.redirect,
    component: () => import("/vercel/path0/pages/completar-cadastro/endereco.vue").then(m => m.default || m)
  },
  {
    name: index86DyjHJUcRMeta?.name ?? "completar-cadastro",
    path: index86DyjHJUcRMeta?.path ?? "/completar-cadastro",
    meta: index86DyjHJUcRMeta || {},
    alias: index86DyjHJUcRMeta?.alias || [],
    redirect: index86DyjHJUcRMeta?.redirect,
    component: () => import("/vercel/path0/pages/completar-cadastro/index.vue").then(m => m.default || m)
  },
  {
    name: informacoes_45pessoaisFrjlULOZmjMeta?.name ?? "completar-cadastro-informacoes-pessoais",
    path: informacoes_45pessoaisFrjlULOZmjMeta?.path ?? "/completar-cadastro/informacoes-pessoais",
    meta: informacoes_45pessoaisFrjlULOZmjMeta || {},
    alias: informacoes_45pessoaisFrjlULOZmjMeta?.alias || [],
    redirect: informacoes_45pessoaisFrjlULOZmjMeta?.redirect,
    component: () => import("/vercel/path0/pages/completar-cadastro/informacoes-pessoais.vue").then(m => m.default || m)
  },
  {
    name: _91partner_93iMbdBCyiWqMeta?.name ?? "crie-sua-conta-partner",
    path: _91partner_93iMbdBCyiWqMeta?.path ?? "/crie-sua-conta/:partner()",
    meta: _91partner_93iMbdBCyiWqMeta || {},
    alias: _91partner_93iMbdBCyiWqMeta?.alias || [],
    redirect: _91partner_93iMbdBCyiWqMeta?.redirect,
    component: () => import("/vercel/path0/pages/crie-sua-conta/[partner].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RHrOUbVTVHMeta?.name ?? "crie-sua-conta-b2b-slug",
    path: _91slug_93RHrOUbVTVHMeta?.path ?? "/crie-sua-conta/b2b/:slug()",
    meta: _91slug_93RHrOUbVTVHMeta || {},
    alias: _91slug_93RHrOUbVTVHMeta?.alias || [],
    redirect: _91slug_93RHrOUbVTVHMeta?.redirect,
    component: () => import("/vercel/path0/pages/crie-sua-conta/b2b/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9320GULkO4OkMeta?.name ?? "crie-sua-conta-convite-slug",
    path: _91slug_9320GULkO4OkMeta?.path ?? "/crie-sua-conta/convite/:slug()",
    meta: _91slug_9320GULkO4OkMeta || {},
    alias: _91slug_9320GULkO4OkMeta?.alias || [],
    redirect: _91slug_9320GULkO4OkMeta?.redirect,
    component: () => import("/vercel/path0/pages/crie-sua-conta/convite/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: confirmacao70Yjj3YzNBMeta?.name ?? "investir-operationId-confirmacao",
    path: confirmacao70Yjj3YzNBMeta?.path ?? "/investir/:operationId()/confirmacao",
    meta: confirmacao70Yjj3YzNBMeta || {},
    alias: confirmacao70Yjj3YzNBMeta?.alias || [],
    redirect: confirmacao70Yjj3YzNBMeta?.redirect,
    component: () => import("/vercel/path0/pages/investir/[operationId]/confirmacao.vue").then(m => m.default || m)
  },
  {
    name: detalhesStrVcJdZlsMeta?.name ?? "investir-operationId-detalhes",
    path: detalhesStrVcJdZlsMeta?.path ?? "/investir/:operationId()/detalhes",
    meta: detalhesStrVcJdZlsMeta || {},
    alias: detalhesStrVcJdZlsMeta?.alias || [],
    redirect: detalhesStrVcJdZlsMeta?.redirect,
    component: () => import("/vercel/path0/pages/investir/[operationId]/detalhes.vue").then(m => m.default || m)
  },
  {
    name: enquadramentoQJEQ0X0Sy4Meta?.name ?? "investir-operationId-enquadramento",
    path: enquadramentoQJEQ0X0Sy4Meta?.path ?? "/investir/:operationId()/enquadramento",
    meta: enquadramentoQJEQ0X0Sy4Meta || {},
    alias: enquadramentoQJEQ0X0Sy4Meta?.alias || [],
    redirect: enquadramentoQJEQ0X0Sy4Meta?.redirect,
    component: () => import("/vercel/path0/pages/investir/[operationId]/enquadramento.vue").then(m => m.default || m)
  },
  {
    name: index9byNyU8QEBMeta?.name ?? "investir-operationId",
    path: index9byNyU8QEBMeta?.path ?? "/investir/:operationId()",
    meta: index9byNyU8QEBMeta || {},
    alias: index9byNyU8QEBMeta?.alias || [],
    redirect: index9byNyU8QEBMeta?.redirect,
    component: () => import("/vercel/path0/pages/investir/[operationId]/index.vue").then(m => m.default || m)
  },
  {
    name: inicionUWCXNIOl5Meta?.name ?? undefined,
    path: inicionUWCXNIOl5Meta?.path ?? "/investir/:operationId()/inicio",
    meta: inicionUWCXNIOl5Meta || {},
    alias: inicionUWCXNIOl5Meta?.alias || [],
    redirect: inicionUWCXNIOl5Meta?.redirect,
    component: () => import("/vercel/path0/pages/investir/[operationId]/inicio.vue").then(m => m.default || m),
    children: [
  {
    name: confirmar_45contah1Ik4YH8FOMeta?.name ?? "investir-operationId-inicio-confirmar-conta",
    path: confirmar_45contah1Ik4YH8FOMeta?.path ?? "confirmar-conta",
    meta: confirmar_45contah1Ik4YH8FOMeta || {},
    alias: confirmar_45contah1Ik4YH8FOMeta?.alias || [],
    redirect: confirmar_45contah1Ik4YH8FOMeta?.redirect,
    component: () => import("/vercel/path0/pages/investir/[operationId]/inicio/confirmar-conta.vue").then(m => m.default || m)
  },
  {
    name: criar_45contaSkDQ0tQphHMeta?.name ?? "investir-operationId-inicio-criar-conta",
    path: criar_45contaSkDQ0tQphHMeta?.path ?? "criar-conta",
    meta: criar_45contaSkDQ0tQphHMeta || {},
    alias: criar_45contaSkDQ0tQphHMeta?.alias || [],
    redirect: criar_45contaSkDQ0tQphHMeta?.redirect,
    component: () => import("/vercel/path0/pages/investir/[operationId]/inicio/criar-conta.vue").then(m => m.default || m)
  },
  {
    name: index4OzrzJy2oHMeta?.name ?? "investir-operationId-inicio",
    path: index4OzrzJy2oHMeta?.path ?? "",
    meta: index4OzrzJy2oHMeta || {},
    alias: index4OzrzJy2oHMeta?.alias || [],
    redirect: index4OzrzJy2oHMeta?.redirect,
    component: () => import("/vercel/path0/pages/investir/[operationId]/inicio/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91indicationCode_93aysZWVdVIcMeta?.name ?? "novo-investidor-indicationCode",
    path: _91indicationCode_93aysZWVdVIcMeta?.path ?? "/novo-investidor/:indicationCode()",
    meta: _91indicationCode_93aysZWVdVIcMeta || {},
    alias: _91indicationCode_93aysZWVdVIcMeta?.alias || [],
    redirect: _91indicationCode_93aysZWVdVIcMeta?.redirect,
    component: () => import("/vercel/path0/pages/novo-investidor/[indicationCode].vue").then(m => m.default || m)
  },
  {
    name: _91operationId_93EBtug1RzAtMeta?.name ?? "oportunidades-operationId",
    path: _91operationId_93EBtug1RzAtMeta?.path ?? "/oportunidades/:operationId()",
    meta: _91operationId_93EBtug1RzAtMeta || {},
    alias: _91operationId_93EBtug1RzAtMeta?.alias || [],
    redirect: _91operationId_93EBtug1RzAtMeta?.redirect,
    component: () => import("/vercel/path0/pages/oportunidades/[operationId].vue").then(m => m.default || m)
  },
  {
    name: finalizadasrSqUoNhD9JMeta?.name ?? "oportunidades-finalizadas",
    path: finalizadasrSqUoNhD9JMeta?.path ?? "/oportunidades/finalizadas",
    meta: finalizadasrSqUoNhD9JMeta || {},
    alias: finalizadasrSqUoNhD9JMeta?.alias || [],
    redirect: finalizadasrSqUoNhD9JMeta?.redirect,
    component: () => import("/vercel/path0/pages/oportunidades/finalizadas.vue").then(m => m.default || m)
  },
  {
    name: historicoFExxblQTIIMeta?.name ?? "oportunidades-historico",
    path: historicoFExxblQTIIMeta?.path ?? "/oportunidades/historico",
    meta: historicoFExxblQTIIMeta || {},
    alias: historicoFExxblQTIIMeta?.alias || [],
    redirect: historicoFExxblQTIIMeta?.redirect,
    component: () => import("/vercel/path0/pages/oportunidades/historico.vue").then(m => m.default || m)
  },
  {
    name: index6E1pbgfjLrMeta?.name ?? "oportunidades",
    path: index6E1pbgfjLrMeta?.path ?? "/oportunidades",
    meta: index6E1pbgfjLrMeta || {},
    alias: index6E1pbgfjLrMeta?.alias || [],
    redirect: index6E1pbgfjLrMeta?.redirect,
    component: () => import("/vercel/path0/pages/oportunidades/index.vue").then(m => m.default || m)
  },
  {
    name: _91operationId_93Z4obi6RG6VMeta?.name ?? "oportunidades-parceiro-operationId",
    path: _91operationId_93Z4obi6RG6VMeta?.path ?? "/oportunidades/parceiro/:operationId()",
    meta: _91operationId_93Z4obi6RG6VMeta || {},
    alias: _91operationId_93Z4obi6RG6VMeta?.alias || [],
    redirect: _91operationId_93Z4obi6RG6VMeta?.redirect,
    component: () => import("/vercel/path0/pages/oportunidades/parceiro/[operationId].vue").then(m => m.default || m)
  },
  {
    name: indexB112Rdsc1eMeta?.name ?? "originadoras",
    path: indexB112Rdsc1eMeta?.path ?? "/originadoras",
    meta: indexB112Rdsc1eMeta || {},
    alias: indexB112Rdsc1eMeta?.alias || [],
    redirect: indexB112Rdsc1eMeta?.redirect,
    component: () => import("/vercel/path0/pages/originadoras/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tOIOCumazRMeta?.name ?? "painel-ajuda-slug",
    path: _91slug_93tOIOCumazRMeta?.path ?? "/painel/ajuda/:slug()",
    meta: _91slug_93tOIOCumazRMeta || {},
    alias: _91slug_93tOIOCumazRMeta?.alias || [],
    redirect: _91slug_93tOIOCumazRMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/ajuda/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlIaHMICzvpMeta?.name ?? "painel-ajuda",
    path: indexlIaHMICzvpMeta?.path ?? "/painel/ajuda",
    meta: indexlIaHMICzvpMeta || {},
    alias: indexlIaHMICzvpMeta?.alias || [],
    redirect: indexlIaHMICzvpMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/ajuda/index.vue").then(m => m.default || m)
  },
  {
    name: _91operationId_93ohfKyvzqhfMeta?.name ?? "painel-detalhes-investimento-operationId",
    path: _91operationId_93ohfKyvzqhfMeta?.path ?? "/painel/detalhes-investimento/:operationId()",
    meta: _91operationId_93ohfKyvzqhfMeta || {},
    alias: _91operationId_93ohfKyvzqhfMeta?.alias || [],
    redirect: _91operationId_93ohfKyvzqhfMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/detalhes-investimento/[operationId].vue").then(m => m.default || m)
  },
  {
    name: index4zvfyrDpQuMeta?.name ?? "painel",
    path: index4zvfyrDpQuMeta?.path ?? "/painel",
    meta: index4zvfyrDpQuMeta || {},
    alias: index4zvfyrDpQuMeta?.alias || [],
    redirect: index4zvfyrDpQuMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/index.vue").then(m => m.default || m)
  },
  {
    name: indexXyMqzEaGQPMeta?.name ?? "painel-indicacao",
    path: indexXyMqzEaGQPMeta?.path ?? "/painel/indicacao",
    meta: indexXyMqzEaGQPMeta || {},
    alias: indexXyMqzEaGQPMeta?.alias || [],
    redirect: indexXyMqzEaGQPMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/indicacao/index.vue").then(m => m.default || m)
  },
  {
    name: indexjriJGez7d4Meta?.name ?? "painel-ir",
    path: indexjriJGez7d4Meta?.path ?? "/painel/ir",
    meta: indexjriJGez7d4Meta || {},
    alias: indexjriJGez7d4Meta?.alias || [],
    redirect: indexjriJGez7d4Meta?.redirect,
    component: () => import("/vercel/path0/pages/painel/ir/index.vue").then(m => m.default || m)
  },
  {
    name: indexoRFjcRdhR3Meta?.name ?? "painel-pendentes",
    path: indexoRFjcRdhR3Meta?.path ?? "/painel/pendentes",
    meta: indexoRFjcRdhR3Meta || {},
    alias: indexoRFjcRdhR3Meta?.alias || [],
    redirect: indexoRFjcRdhR3Meta?.redirect,
    component: () => import("/vercel/path0/pages/painel/pendentes/index.vue").then(m => m.default || m)
  },
  {
    name: index4ge3cW3RvRMeta?.name ?? "painel-perfil-alterar-email",
    path: index4ge3cW3RvRMeta?.path ?? "/painel/perfil/alterar-email",
    meta: index4ge3cW3RvRMeta || {},
    alias: index4ge3cW3RvRMeta?.alias || [],
    redirect: index4ge3cW3RvRMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/perfil/alterar-email/index.vue").then(m => m.default || m)
  },
  {
    name: indextJn3HW0S6ZMeta?.name ?? "painel-perfil-alterar-senha",
    path: indextJn3HW0S6ZMeta?.path ?? "/painel/perfil/alterar-senha",
    meta: indextJn3HW0S6ZMeta || {},
    alias: indextJn3HW0S6ZMeta?.alias || [],
    redirect: indextJn3HW0S6ZMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/perfil/alterar-senha/index.vue").then(m => m.default || m)
  },
  {
    name: index51TLsa3RqnMeta?.name ?? "painel-perfil-alterar-telefone",
    path: index51TLsa3RqnMeta?.path ?? "/painel/perfil/alterar-telefone",
    meta: index51TLsa3RqnMeta || {},
    alias: index51TLsa3RqnMeta?.alias || [],
    redirect: index51TLsa3RqnMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/perfil/alterar-telefone/index.vue").then(m => m.default || m)
  },
  {
    name: adicionar5DbZF45VAkMeta?.name ?? "painel-perfil-chave-pix-adicionar",
    path: adicionar5DbZF45VAkMeta?.path ?? "/painel/perfil/chave-pix/adicionar",
    meta: adicionar5DbZF45VAkMeta || {},
    alias: adicionar5DbZF45VAkMeta?.alias || [],
    redirect: adicionar5DbZF45VAkMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/perfil/chave-pix/adicionar.vue").then(m => m.default || m)
  },
  {
    name: indexcNoZLf9kikMeta?.name ?? "painel-perfil-chave-pix",
    path: indexcNoZLf9kikMeta?.path ?? "/painel/perfil/chave-pix",
    meta: indexcNoZLf9kikMeta || {},
    alias: indexcNoZLf9kikMeta?.alias || [],
    redirect: indexcNoZLf9kikMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/perfil/chave-pix/index.vue").then(m => m.default || m)
  },
  {
    name: editarBlm77FzKTnMeta?.name ?? "painel-perfil-dados-bancarios-id-editar",
    path: editarBlm77FzKTnMeta?.path ?? "/painel/perfil/dados-bancarios/:id()/editar",
    meta: editarBlm77FzKTnMeta || {},
    alias: editarBlm77FzKTnMeta?.alias || [],
    redirect: editarBlm77FzKTnMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/perfil/dados-bancarios/[id]/editar.vue").then(m => m.default || m)
  },
  {
    name: adicionarv4gx5QDWNTMeta?.name ?? "painel-perfil-dados-bancarios-adicionar",
    path: adicionarv4gx5QDWNTMeta?.path ?? "/painel/perfil/dados-bancarios/adicionar",
    meta: adicionarv4gx5QDWNTMeta || {},
    alias: adicionarv4gx5QDWNTMeta?.alias || [],
    redirect: adicionarv4gx5QDWNTMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/perfil/dados-bancarios/adicionar.vue").then(m => m.default || m)
  },
  {
    name: indexSS8G5cbybdMeta?.name ?? "painel-perfil-dados-bancarios",
    path: indexSS8G5cbybdMeta?.path ?? "/painel/perfil/dados-bancarios",
    meta: indexSS8G5cbybdMeta || {},
    alias: indexSS8G5cbybdMeta?.alias || [],
    redirect: indexSS8G5cbybdMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/perfil/dados-bancarios/index.vue").then(m => m.default || m)
  },
  {
    name: index6PTCbwI1a2Meta?.name ?? "painel-perfil-dados-patrimoniais",
    path: index6PTCbwI1a2Meta?.path ?? "/painel/perfil/dados-patrimoniais",
    meta: index6PTCbwI1a2Meta || {},
    alias: index6PTCbwI1a2Meta?.alias || [],
    redirect: index6PTCbwI1a2Meta?.redirect,
    component: () => import("/vercel/path0/pages/painel/perfil/dados-patrimoniais/index.vue").then(m => m.default || m)
  },
  {
    name: index0iybtMw4bpMeta?.name ?? "painel-perfil-dados-pessoais",
    path: index0iybtMw4bpMeta?.path ?? "/painel/perfil/dados-pessoais",
    meta: index0iybtMw4bpMeta || {},
    alias: index0iybtMw4bpMeta?.alias || [],
    redirect: index0iybtMw4bpMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/perfil/dados-pessoais/index.vue").then(m => m.default || m)
  },
  {
    name: indexk4WHrAo1f1Meta?.name ?? "painel-perfil-desativar-conta",
    path: indexk4WHrAo1f1Meta?.path ?? "/painel/perfil/desativar-conta",
    meta: indexk4WHrAo1f1Meta || {},
    alias: indexk4WHrAo1f1Meta?.alias || [],
    redirect: indexk4WHrAo1f1Meta?.redirect,
    component: () => import("/vercel/path0/pages/painel/perfil/desativar-conta/index.vue").then(m => m.default || m)
  },
  {
    name: index9DLGyTJwaDMeta?.name ?? "painel-perfil-documentos",
    path: index9DLGyTJwaDMeta?.path ?? "/painel/perfil/documentos",
    meta: index9DLGyTJwaDMeta || {},
    alias: index9DLGyTJwaDMeta?.alias || [],
    redirect: index9DLGyTJwaDMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/perfil/documentos/index.vue").then(m => m.default || m)
  },
  {
    name: indexOCaA4S2QoKMeta?.name ?? "painel-perfil-endereco",
    path: indexOCaA4S2QoKMeta?.path ?? "/painel/perfil/endereco",
    meta: indexOCaA4S2QoKMeta || {},
    alias: indexOCaA4S2QoKMeta?.alias || [],
    redirect: indexOCaA4S2QoKMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/perfil/endereco/index.vue").then(m => m.default || m)
  },
  {
    name: indexnjmxEyuke3Meta?.name ?? "painel-perfil",
    path: indexnjmxEyuke3Meta?.path ?? "/painel/perfil",
    meta: indexnjmxEyuke3Meta || {},
    alias: indexnjmxEyuke3Meta?.alias || [],
    redirect: indexnjmxEyuke3Meta?.redirect,
    component: () => import("/vercel/path0/pages/painel/perfil/index.vue").then(m => m.default || m)
  },
  {
    name: _91assetSymbol_93GUBCS5HFzHMeta?.name ?? "painel-wallet-assetSymbol",
    path: _91assetSymbol_93GUBCS5HFzHMeta?.path ?? "/painel/wallet/:assetSymbol()",
    meta: _91assetSymbol_93GUBCS5HFzHMeta || {},
    alias: _91assetSymbol_93GUBCS5HFzHMeta?.alias || [],
    redirect: _91assetSymbol_93GUBCS5HFzHMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/wallet/[assetSymbol].vue").then(m => m.default || m)
  },
  {
    name: depositoVEnQ0vDnLTMeta?.name ?? "painel-wallet-deposito",
    path: depositoVEnQ0vDnLTMeta?.path ?? "/painel/wallet/deposito",
    meta: depositoVEnQ0vDnLTMeta || {},
    alias: depositoVEnQ0vDnLTMeta?.alias || [],
    redirect: depositoVEnQ0vDnLTMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/wallet/deposito.vue").then(m => m.default || m)
  },
  {
    name: indexkGK62At1OMMeta?.name ?? "painel-wallet",
    path: indexkGK62At1OMMeta?.path ?? "/painel/wallet",
    meta: indexkGK62At1OMMeta || {},
    alias: indexkGK62At1OMMeta?.alias || [],
    redirect: indexkGK62At1OMMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/wallet/index.vue").then(m => m.default || m)
  },
  {
    name: resgateuqCnhvWZRDMeta?.name ?? "painel-wallet-resgate",
    path: resgateuqCnhvWZRDMeta?.path ?? "/painel/wallet/resgate",
    meta: resgateuqCnhvWZRDMeta || {},
    alias: resgateuqCnhvWZRDMeta?.alias || [],
    redirect: resgateuqCnhvWZRDMeta?.redirect,
    component: () => import("/vercel/path0/pages/painel/wallet/resgate.vue").then(m => m.default || m)
  },
  {
    name: indexQpXVhpZ6xmMeta?.name ?? "parceiros",
    path: indexQpXVhpZ6xmMeta?.path ?? "/parceiros",
    meta: indexQpXVhpZ6xmMeta || {},
    alias: indexQpXVhpZ6xmMeta?.alias || [],
    redirect: indexQpXVhpZ6xmMeta?.redirect,
    component: () => import("/vercel/path0/pages/parceiros/index.vue").then(m => m.default || m)
  },
  {
    name: indexW75ZCJS0M5Meta?.name ?? "politica-de-privacidade",
    path: indexW75ZCJS0M5Meta?.path ?? "/politica-de-privacidade",
    meta: indexW75ZCJS0M5Meta || {},
    alias: indexW75ZCJS0M5Meta?.alias || [],
    redirect: indexW75ZCJS0M5Meta?.redirect,
    component: () => import("/vercel/path0/pages/politica-de-privacidade/index.vue").then(m => m.default || m)
  },
  {
    name: indexbe0Pcqyc0GMeta?.name ?? "quem-somos",
    path: indexbe0Pcqyc0GMeta?.path ?? "/quem-somos",
    meta: indexbe0Pcqyc0GMeta || {},
    alias: indexbe0Pcqyc0GMeta?.alias || [],
    redirect: indexbe0Pcqyc0GMeta?.redirect,
    component: () => import("/vercel/path0/pages/quem-somos/index.vue").then(m => m.default || m)
  },
  {
    name: index2zCys330d0Meta?.name ?? "remocao-de-conta",
    path: index2zCys330d0Meta?.path ?? "/remocao-de-conta",
    meta: index2zCys330d0Meta || {},
    alias: index2zCys330d0Meta?.alias || [],
    redirect: index2zCys330d0Meta?.redirect,
    component: () => import("/vercel/path0/pages/remocao-de-conta/index.vue").then(m => m.default || m)
  },
  {
    name: _91signupCode_93WVrBrc7ffnMeta?.name ?? "seja-investidor-signupCode",
    path: _91signupCode_93WVrBrc7ffnMeta?.path ?? "/seja-investidor/:signupCode()",
    meta: _91signupCode_93WVrBrc7ffnMeta || {},
    alias: _91signupCode_93WVrBrc7ffnMeta?.alias || [],
    redirect: _91signupCode_93WVrBrc7ffnMeta?.redirect,
    component: () => import("/vercel/path0/pages/seja-investidor/[signupCode].vue").then(m => m.default || m)
  },
  {
    name: indexzay5PkV141Meta?.name ?? "termos-de-uso",
    path: indexzay5PkV141Meta?.path ?? "/termos-de-uso",
    meta: indexzay5PkV141Meta || {},
    alias: indexzay5PkV141Meta?.alias || [],
    redirect: indexzay5PkV141Meta?.redirect,
    component: () => import("/vercel/path0/pages/termos-de-uso/index.vue").then(m => m.default || m)
  },
  {
    name: indexP3dhkR4w1VMeta?.name ?? "tokenizacao",
    path: indexP3dhkR4w1VMeta?.path ?? "/tokenizacao",
    meta: indexP3dhkR4w1VMeta || {},
    alias: indexP3dhkR4w1VMeta?.alias || [],
    redirect: indexP3dhkR4w1VMeta?.redirect,
    component: () => import("/vercel/path0/pages/tokenizacao/index.vue").then(m => m.default || m)
  }
]