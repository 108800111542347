<script setup lang="ts">
import { firebaseAnalytics } from "../infra/firebase/firebaseAnalytics/firebase-analytics-helper";
import AppHeader from "@/components/AppHeader.vue";
import { SidebarRegister } from "@/components/SidebarRegister";
import { SidebarAutenticate } from "@/components/SidebarAutenticate";
import { ForgotPasswordDrawer } from "@/components/ForgotPasswordDrawer";
import { getAccountById } from "@/services/account";
import { type SignInResponse, useAuthStore } from "@/store/auth";
import {
  localStorageGet,
  localStorageSet,
} from "@/utils/internet/local-storage";
import { useRawCookie } from "@/composables/useRawCookie";
import { makeAuthHeader } from "@/utils/auth/make-auth-header";
import { useAppStore } from "~/store/app";
import { setup3rdPartyScripts } from "~/utils/internet/setup-3rd-party-scripts";

useLandingHead();

const env = useRuntimeConfig();

const token = useCookie("token").value;
const account = useCookie("account").value;
const appStore = useAppStore();

if (token && account) {
  const serverAccountRequest = await useFetch(
    `${env.public.apiBaseURL}/account/${account}`,
    {
      server: true,
      headers: {
        ...makeAuthHeader(),
      },
    },
  );

  if (serverAccountRequest.error.value) {
    const refreshTokenCookie = useCookie("refresh_token").value;
    const nidCookie = useRawCookie("nid").value;
    if (nidCookie && refreshTokenCookie) {
      const serverRefreshTokenRequest = await useFetch(
        `${env.public.apiBaseURL}/account/notauth/refresh-token`,
        {
          server: true,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: refreshTokenCookie,
            nid: nidCookie,
          }),
        },
      );

      if (serverRefreshTokenRequest.data.value) {
        const { data } = serverRefreshTokenRequest.data.value as any;

        const THIRTY_DAYS = 60 * 60 * 24 * 30;

        const tokenCookie = useCookie("token", {
          path: "/",
          maxAge: THIRTY_DAYS,
        });
        tokenCookie.value = data.token;

        const refreshTokenCookie = useCookie("refresh_token", {
          path: "/",
          maxAge: THIRTY_DAYS,
        });

        refreshTokenCookie.value = data.refresh_token;

        const accountCookie = useCookie("account", {
          path: "/",
          maxAge: THIRTY_DAYS,
        });

        accountCookie.value = data.account_id;
      }

      if (serverRefreshTokenRequest.error.value) {
        const singOut = useSignOut();
        singOut();
      }
    }
  }
}

onBeforeMount(async () => {
  if (token && account) {
    try {
      await getAccountById(account);
    } catch (error) {
      const signOut = useSignOut();
      signOut();
    }
  }

  if (typeof window === "undefined") return;

  const auth = useAuthStore();
  if (auth.isAuthenticated) {
    const tokenCookie = useCookie("token");
    const refreshTokenCookie = useCookie("refresh_token");
    const legacyAuth = localStorageGet<SignInResponse>("HCP-AUTH");

    if (legacyAuth) {
      localStorageSet("HCP-AUTH", {
        ...legacyAuth,
        token: tokenCookie.value,
        refresh_token: refreshTokenCookie.value,
        isAuth: true,
      });
    } else {
      auth.signOut();
    }
  }
});

onMounted(() => {
  if (process.client && env.public.stage === "prod") {
    firebaseAnalytics();
    setup3rdPartyScripts();
  }

  if (process.client) {
    document.addEventListener("click", (event) => {
      const target = event.target as HTMLElement;
      if (typeof target.dataset.onclick === "string") {
        event.preventDefault();
      }

      if (target.dataset.onclick === "signup") {
        appStore.setOpenSidebarRegister(true);
      }
    });
  }
});
</script>
<template>
  <div>
    <AppHeader />
    <main>
      <suspense>
        <slot></slot>
      </suspense>
    </main>
    <ForgotPasswordDrawer />
    <SidebarAutenticate />
    <SidebarRegister />
    <NuxtIsland name="AppFooter" />
  </div>
</template>
