import type { RestAccount } from "~/composables/api/account/useAccount";
import { useSetTokenExpiration } from "~/composables/useSetTokenExpiration";
import type { SignInResponse } from "~/store/auth";
import {
  localStorageGet,
  localStorageSet,
} from "~/utils/internet/local-storage";

interface RefreshTokenData {
  accessToken: string;
  refreshToken: string;
  accessTokenExpiresIn: number;
  refreshTokenExpiresIn: number;
  account: RestAccount;
}

interface RefreshTokenResponse {
  data: RefreshTokenData;
}

export default defineNuxtPlugin(() => {
  let refreshPromise: any = null;
  let refreshing = false;

  const setRefreshTokenExpiration = useSetRefreshTokenExpiration();
  const setTokenExpiration = useSetTokenExpiration();
  const cookieOptions = { path: "/", maxAge: 60 * 60 * 24 * 30 };

  const refreshTokenCookie = useCookie("refresh_token", cookieOptions);
  const tokenCookie = useCookie("token", cookieOptions);
  const nidCookie = useRawCookie("nid", cookieOptions);
  const accountCookie = useCookie("account", cookieOptions);
  const env = useRuntimeConfig();

  const token = ref(tokenCookie.value);
  watch(tokenCookie, (value) => {
    token.value = value;
  });

  const refresh = async (): Promise<RefreshTokenData | null> => {
    const res = await $fetch<RefreshTokenResponse>("/token/refresh", {
      baseURL: env.public.authBaseURL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(tokenCookie.value && { Authorization: tokenCookie.value }),
      },
      body: {
        refreshToken: refreshTokenCookie.value,
      },
    });
    const data = res.data;

    if (!data) return null;

    token.value = data.accessToken;

    refreshTokenCookie.value = data.refreshToken;
    tokenCookie.value = data.accessToken;
    nidCookie.value = data.account.nid;
    accountCookie.value = data.account.id;
    setRefreshTokenExpiration(data?.refreshTokenExpiresIn);
    setTokenExpiration(data?.accessTokenExpiresIn);

    const authStoraged = localStorageGet<SignInResponse>("HCP-AUTH");

    if (authStoraged) {
      localStorageSet("HCP-AUTH", {
        ...authStoraged,
        token: data.accessToken,
        refresh_token: data.refreshToken,
      });
    }

    return data;
  };

  const refreshLock = async () => {
    if (refreshing) {
      try {
        await refreshPromise;
      } catch {}
    }
  };

  const performTokenRefresh = (): Promise<RefreshTokenData | null> => {
    if (refreshing) {
      return refreshPromise;
    }

    refreshPromise = refresh();
    refreshing = true;
    refreshPromise.finally(() => {
      refreshing = false;
    });
    return refreshPromise;
  };

  return { provide: { performTokenRefresh, refreshLock, token } };
});
