import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hAQZccAnsC from "/vercel/path0/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_refresh_bgJehlepk2 from "/vercel/path0/plugins/01.refresh.ts";
import api_GFfDXud5Cr from "/vercel/path0/plugins/api.ts";
import dayjs_pGiXRjcsJO from "/vercel/path0/plugins/dayjs.ts";
import gql_3PotKuelAp from "/vercel/path0/plugins/gql.ts";
import i18n_VfGcjrvSkj from "/vercel/path0/plugins/i18n.ts";
import maska_UHaKf2z1iQ from "/vercel/path0/plugins/maska.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
import vue_query_wrmMkNpEpe from "/vercel/path0/plugins/vue-query.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  debug_hAQZccAnsC,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  chunk_reload_client_UciE0i6zes,
  _01_refresh_bgJehlepk2,
  api_GFfDXud5Cr,
  dayjs_pGiXRjcsJO,
  gql_3PotKuelAp,
  i18n_VfGcjrvSkj,
  maska_UHaKf2z1iQ,
  sentry_client_shVUlIjFLk,
  vue_query_wrmMkNpEpe
]